<template>
  <v-navigation-drawer :right="rtlLayout" :mini-variant="miniSidebar"
    :expand-on-hover="miniSidebar" :dark="dark" app clipped permanent>
    <v-list>
      <v-list-item-group color="primary" mandatory>
        <template v-for="item in menu">
          <template v-if="!item.items">
            <v-list-item :key="item.title" :to="item.link" exact>
              <template v-slot:default="{}">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
              </template>
            </v-list-item>
          </template>
          <template v-else>
            <v-list-group :value="true" :key="item.title" no-action v-model="item.active">
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
              </template>
              <v-list-item v-for="subItem in item.items" :key="subItem.title" :to="subItem.link" exact>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon size="20">{{ subItem.icon }}</v-icon> {{ $t(subItem.title) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </template>
        </template>
        <template v-if="helpDesks.items.length > 0">
          <v-list-group :value="true" :key="helpDesks.title" no-action v-model="helpDesks.active">
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>{{ helpDesks.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t(helpDesks.title) }}</v-list-item-title>
            </template>
            <template v-for="subItem in helpDesks.items">
              <v-list-item v-if="subItem.external" :key="subItem.title" :href="subItem.link" target="_blank">
                <template v-slot:default="{}">
                  <v-list-item-title>{{subItem.title}}</v-list-item-title>
                </template>
              </v-list-item>
              <v-list-item v-else :key="subItem.title" :to="subItem.link" exact>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon size="20">{{ subItem.icon }}</v-icon> {{ $t(subItem.title) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
        </template>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'Sidebar',
  computed: {
    ...mapGetters(['mainMenu', 'miniSidebar', 'rtlLayout', 'isAdmin', 'currentUser', 'currentHelpDesks']),
    menu() {
      const temp = [];
      this._.map(this.mainMenu, (item) => {
        if (this.isPermittedRoute(item)) {
          if (item.items) {
            const parentItem = {...item, items: []};
            this._.map(item.items, (subItem) => {
              if (this.isPermittedRoute(subItem)) {
                if (this.$route.name === subItem.link.name) {
                  parentItem.active = true;
                }
                parentItem.items.push(subItem);
              }
            });
            temp.push(parentItem);
          } else {
            temp.push(item);
          }
        }
      });
      return temp;
    },
    helpDesks() {
      const temp = {
        title: 'layout.mainMenu.helpDesks',
        icon: 'mdi-face-agent',
        link: {},
        items: [],
        active: this.$route.name === 'HelpDesks'
      };
      this._.forEach(this.currentHelpDesks, (helpDesk) => {
        temp.items.push({
          title: helpDesk.name,
          link: helpDesk.link,
          external: true
        });
      });
      if (this.isAdmin) {
        temp.items.push({
          title: 'layout.accountNavMenu.editHelpDeskLinks',
          icon: 'mdi-pencil',
          link: {name: 'HelpDesks'}
        });
      }
      return temp;
    }
  },
  props: {
    dark: {
      type: Boolean,
      default: false
    },
    adminData: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style>
.v-navigation-drawer__border {
  display: none;
}
</style>
