<template>
  <v-app>
    <app-navbar />
    <app-sidebar />
    <v-content class="secondary-background">
      <v-container fluid class="px-lg-5">
        <v-app-bar prominent dense class="p-0 m-0" flat>
          <v-toolbar-title class="mx-6 display-1">
            <app-avatar :url="getFileUrl(user.avatar)" :alternative="user.initials" size="medium" />
            {{ user.name }}
          </v-toolbar-title>
          <template v-slot:extension>
            <v-tabs align-with-title center-active class="white--text headline" show-arrows v-model="tab">
              <v-tab v-for="item in menu" :to="item.link" :key="item.to">{{ $t(item.title) }}</v-tab>
            </v-tabs>
          </template>
        </v-app-bar>
        <v-row no-gutters align-content="center" justify="center" v-if="dataLoaded">
          <v-card outlined flat class="fill-height my-5 mx-5" width="100%">
            <v-card-title class="justify-center text-center" v-text="cardTitle" />
            <v-card-subtitle class="justify-center text-center" v-text="cardSubtitle" />
            <router-view class="page-wrapper" />
          </v-card>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import AppNavbar from 'components/layout/navbar';
import AppSidebar from 'components/layout/sidebar';

import {
  mapActions,
  mapGetters
} from 'vuex';

export default {
  name: 'ProfileLayout',
  components: {
    AppNavbar,
    AppSidebar
  },
  computed: {
    ...mapGetters(['userMenu', 'user', 'isAdmin', 'currentUser']),
    menu() {
      const temp = [];
      this._.map(this.userMenu, (item) => {
        if (this.isPermittedRoute(item)) {
          temp.push(item);
        }
      });
      return temp;
    }
  },
  data() {
    return {
      tab: '',
      cardTitle: '',
      cardSubtitle: '',
      dataLoaded: false
    };
  },
  methods: {
    ...mapActions(['getUser']),
    getUserInfo() {
      this.getUser({uuid: this.$route.params.uuid}).then(() => {
        this.dataLoaded = true;
      });
    }
  },
  watch: {
    tab() {
      const tabName = this._.camelCase(this.tab.replace(`users/${this.$route.params.uuid}/`, ''));
      this.cardTitle = this.$t(`layout.${tabName}.title`);
      this.cardSubtitle = this.$t(`layout.${tabName}.subtitle`);
    },
    '$route.params.uuid': function() {
      this.dataLoaded = false;
      this.getUserInfo();
    }
  },
  created() {
    this.getUserInfo();
  }
};
</script>

