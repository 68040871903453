<template>
  <v-app-bar color="background" app clipped-left clipped-right flat>
    <v-app-bar-nav-icon @click="toggleSidebar" />
    <v-toolbar-title>
      <router-link to="/">
        <img :src="getFileUrl(company.logo)" height="55" alt="logo" />
      </router-link>
    </v-toolbar-title>
    <v-spacer />
    <app-account-nav-menu />
  </v-app-bar>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import AppAccountNavMenu from './AccountNavMenu';

export default {
  name: 'Navbar',
  components: {
    AppAccountNavMenu
  },
  computed: mapGetters(['company']),
  methods: mapActions(['toggleSidebar'])
};
</script>
